import React from 'react'
import Form from 'templates/Form'


const content = {
  titleBlock: 'Contact an Expert in China Today.',
  textImageOffset: {
    image: '/images/content/illustrated-team.png',
    // title: 'Come Join in the Fun',
    body: `<p>What Recruitment, EAP or Talent Management project can we help you with today? We want to hear from you. An expert recruiter is ready to learn about your human resource management challenges and create the specialised staffing solutions that will bring great people to great performance!</p>`
  },
  formBlock: {
    title: 'Contact Us',
    form: 'contact',
    contactInfo: true
  }
}

const Contact = () => {
  return (
    <Form content={content} />
  )
}

export default Contact