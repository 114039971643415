import React from 'react'
import { Link } from 'react-router-dom';



const BgLink = (props) => {
  const { to, text, external, isLine, isButton, isWhite } = props;

  const setClassName = `bg-link ${(isLine ? 'line' : '')} ${(isButton ? 'button' : '')} ${(isWhite ? 'white' : '')}`

  if (external) {
    return (
      <a href={to} target="_blank" rel="noopener noreferrer" className={setClassName}>
        <span className="text">{text}</span>
        <span className="bg">
          <span className="inner-text">{text}</span>
        </span>
      </a>
    );
  } else {
    return (
      <Link to={to} className={setClassName}>

          <span className="text">{text}</span>
          <span className="bg">
            <span className="inner-text">{text}</span>
          </span>

      </Link>
    );
  }
};

export default BgLink;
