import React from 'react'
import { pageLoad } from 'js/functions'
import ValuesBlock from 'components/ValuesBlock'
import Banner from 'components/Banner'
import Breadcrumb from 'components/Breadcrumb'
import Footer from 'components/Footer'
import NextSteps from 'components/NextSteps'



class Values extends React.Component {
  componentDidMount() {
    pageLoad()
  }

  render() {
    const nextSteps = {
      headline: 'Ready to get started?',
      one: {
        title: 'Learn more.',
        linkTo: '/about/brand-promise',
        linkText: 'Brand Promise'
      },
      two: {
        title: 'Speak to a consultant.',
        linkTo: '/contact',
        linkText: 'Contact Us'
      }
    }
    return (
      <div id="template-inner">
        <Banner image="shanghai-view-distant" />
        <div id="main-wrap">
          <div className="upper">
            <Breadcrumb />
            <ValuesBlock />
          </div>
          <NextSteps content={nextSteps} />
          <Footer />
        </div>
      </div>
    )
  }
}

export default Values