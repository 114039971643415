import React from 'react'
import Landing from 'templates/Landing'
import { Query } from 'react-apollo'
import gql from 'graphql-tag'



const GET_ITEMS = gql`
  {
    e_retain_items {
      edges {
        node {
          title
          slug
          featuredImage {
            sourceUrl
          }
        }
      }
    }
  }
`

const Index = (props) => {
  return (
    <div>
      <Query query={GET_ITEMS}>
        {({ loading, error, data }) => {
          if (loading) return <div className="loader" />
          if (error) return <p>Error :(</p>
          if (!data.e_retain_items.edges.length) return <p>No matching posts found.</p>

          let items = data.e_retain_items.edges
          return <Inner items={items} />
        }}
      </Query>
    </div>
  )
}



class Inner extends React.Component {
  render() {
    const { items } = this.props
    const content = {
      titleBlock: 'Talent First assists you find the most appropriate job in China.',
      subpageHolder: {
        body: `<p>Talent First RETAIN solutions have a distinct orientation to the management, attraction and retention of your existing and future employees. Talent First will customise solutions that will resolve or bridge gaps in each or any of the following areas:</p>`,
        subpages: items
      },
      textImageBlocks: [
        {
          title: 'Retain the Best Talent',
          body: `<p>Each organisation has its own unique talent management requirements, the Talent First team will assist you and your organisation to achieve best practice and in turn create a more transparent and productive organisation that offers an appealing employee value proposition. Thus increasing your organisations ability to attract and retain the best talent.</p>`,
          image: '/images/content/paper-airplane-background.jpg'
        },
      ],
      nextSteps: {
        headline: 'Ready to get started?',
        one: {
          title: 'Learn More.',
          linkTo: '/employers/retain/coaching-mentoring',
          linkText: 'Coaching & Mentoring'
        },
        two: {
          title: 'Speak to a consultant.',
          linkTo: '/contact',
          linkText: 'Contact Us'
        }
      }
    }
    return (
      <Landing content={content} />
    )
  }
}

export default Index
