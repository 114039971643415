import React from 'react'
import { InView } from 'react-intersection-observer'


const TitleBlock = (props) => {
  const { title } = props
  return (
    <InView>
      {({ inView, ref }) => (
        <div className={`title-block ${(inView ? 'in-view' : '')} fade-in`} ref={ref}>
          <h1 dangerouslySetInnerHTML={{ __html: title }} />
        </div>
      )}
    </InView>

  )
}

export default TitleBlock;

