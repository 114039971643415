import React from 'react'
import { useInView } from 'react-intersection-observer';


const doScrollDown = () => {
  // const top = document.querySelector('#banner').offsetHeight;
  const top = (window.innerHeight - 98)

  window.scroll({
    top: top, 
    left: 0, 
    behavior: 'smooth' 
  });
}

const ScrollDown = () => {
  const [ref, inView] = useInView()
  return (
    <div className={'scroll-down fade-in ' + (inView ? 'in-view' : '')} ref={ref}>
      <button onClick={doScrollDown}></button>
    </div>
  )
}

export default ScrollDown;

