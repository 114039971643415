import React from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { InView } from 'react-intersection-observer'



class Breadcrumb extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      b1: {},
      b2: {},
      b3: {}
    };
  }

  componentDidMount() {
    this.setValues();
  }

  setValues() {
    const m1 = document.querySelector('ul.main > li > a.active');
    if (m1) {
      this.setState({
        b1: {
          text: m1.text,
          link: m1.pathname.split('/').pop()
        }
      })
    }
    const m2 = document.querySelector('ul.sub > li > a.active');
    if (m2) {
      this.setState({
        b2: {
          text: m2.text,
          link: m2.pathname.split('/').pop()
        }
      })
    }
    const m3 = document.querySelector('ul.sub-2 > li > a.active');
    if (m3) {
      this.setState({
        b3: {
          text: m3.text,
          link: m3.pathname.split('/').pop()
        }
      })
    }
  }

  render() {
    let titleTag = "Talent First";
    if (this.state.b1.text) titleTag += ' | ' + this.state.b1.text;
    if (this.state.b2.text) titleTag += ' > ' + this.state.b2.text;
    if (this.state.b3.text) titleTag += ' > ' + this.state.b3.text;
    // if (this.props.b3Text)  titleTag += ' > ' + this.props.b3Text;

    if (this.state.b3 && this.state.b3.text) {
      return (
        <InView>
          {({ inView, ref }) => (
            <div className={`breadcrumb ${(inView ? 'in-view' : '')} fade-in`} ref={ref}>
              <Helmet><title>{titleTag}</title></Helmet>
              <NavLink to={`/${this.state.b1.link}`}>{this.state.b1.text}</NavLink>
              <Arrow />
              <NavLink to={`/${this.state.b1.link}/${this.state.b2.link}`}>{this.state.b2.text}</NavLink>
              <Arrow />
              {this.state.b3.text}
            </div>
          )}
        </InView>
      )
    }
    else if (this.state.b2 && this.state.b2.text) {

      return (
        <InView>
          {({ inView, ref }) => (
            <div className={`breadcrumb ${(inView ? 'in-view' : '')} fade-in`} ref={ref}>
              <Helmet><title>{titleTag}</title></Helmet>
              <NavLink to={`/${this.state.b1.link}`}>{this.state.b1.text}</NavLink>
              <Arrow />
              {this.state.b2.text}
            </div>
          )}
        </InView>
      )
    }
    else if (this.state.b1) {
      return (
        <InView>
          {({ inView, ref }) => (
            <div className={`breadcrumb ${(inView ? 'in-view' : '')} fade-in`} ref={ref}>
              <Helmet><title>{titleTag}</title></Helmet>
              {this.state.b1.text}
            </div>
          )}
        </InView>

      )
    }
  }

}


const Arrow = () => {
  return <span className="arrow-right"></span>
}

export default withRouter(Breadcrumb);
