import React from "react";
import { Switch, Route, withRouter, Redirect } from "react-router-dom"
import Home from 'pages/Home'
import Inner from 'templates/Inner'
import JobSeekersFindIndex from 'pages/JobSeekers/FindIndex'
import EmployersIndex from 'pages/Employers/Index'
import EmployersFindIndex from 'pages/Employers/FindIndex'
import EmployersNurtureIndex from 'pages/Employers/NurtureIndex'
import EmployersRetainIndex from 'pages/Employers/RetainIndex'
import AboutIndex from 'pages/About/Index'
import Values from 'pages/About/Values'
import JoinUs from 'pages/JoinUs'
import Contact from 'pages/Contact'
import NotFound from 'pages/NotFound'
import { pageLoad } from "js/functions"





class Container extends React.Component {

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      pageLoad()
    }
  }

  render() {
    const { location } = this.props
    const baseDir = location.pathname.split('/')[1]
    return (
      <div id="container" className={`section-${baseDir}`}>
        <Switch location={location}>
          <Route path="/" component={Home} exact />
          <Route path="/job-seekers" exact render={() => (<Redirect to="/job-seekers/find" />)} />
          <Route path="/job-seekers/find" exact component={JobSeekersFindIndex} />
          <Route path="/job-seekers/find/:slug" exact component={Inner} />
          <Route path="/employers" exact component={EmployersIndex} />
          <Route path="/employers/find" exact component={EmployersFindIndex} />
          <Route path="/employers/find/:slug" exact component={Inner} />
          <Route path="/employers/nurture" exact component={EmployersNurtureIndex} />
          <Route path="/employers/nurture/:slug" exact component={Inner} />
          <Route path="/employers/retain" exact component={EmployersRetainIndex} />
          <Route path="/employers/retain/:slug" exact component={Inner} />
          <Route path="/about" exact component={AboutIndex} />
          <Route path="/about/brand-values" exact component={Values} />
          <Route path="/about/:slug" exact component={Inner} />
          <Route path="/join-us" exact component={JoinUs} />
          <Route path="/contact" exact component={Contact} />
          <Route component={NotFound} />
        </Switch>
      </div>
    );
  }
}



export default withRouter(Container);
