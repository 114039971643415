import React from 'react'
import ReactPlayer from 'react-player'

class RVideo extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      videoSrc: ''
    };
  }


  setVideoSrc() {
    // get the video pixel width
    const { filename } = this.props
    const videoWidth = document.querySelector('.video').clientWidth
    let devicePixels = window.devicePixelRatio
    if (devicePixels > 2) devicePixels = 2
    const videoPixelWidth = videoWidth * devicePixels

    // loop through existing choices, find the nearest match which is greater than the current video pixel width
    // MBPro = 1448, iPad V = 1396, HD = 1003, iPhone XR = 688
    let widthMatch = 1920
    const widthChoices = [1920, 1280, 960, 640]
    widthChoices.forEach(thisWidth => {
      if (thisWidth > videoPixelWidth) widthMatch = thisWidth // find the nearest match which is greater than the current screen width
    })
    const videoSrc = `/videos/${filename}_${widthMatch}.mp4`
    this.setState({ videoSrc })
  }

  componentDidMount() {
    this.setVideoSrc()
  }

  render() {

    return (
      <div className="video">
        <ReactPlayer
          url={this.state.videoSrc}
          className="react-player"
          loop
          muted
          playsinline
          // playing={this.state.isPlaying}
          playing
          // config={{ file: { 
          //   attributes: {
          //     controlsList: 'nodownload'
          //   }
          // }}}
          // controls
          width="100%"
          height="100%"
        />
      </div>
    )
  }
}

export default RVideo

