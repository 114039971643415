// import axios from 'axios'
import React, { Component } from 'react'
import { Field, Form } from 'react-final-form'
import ReactSVG from 'react-svg'
// import thisEnv from '../../env'
// var hasher = require('wordpress-hash-node')



class ContactForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      error: null,
      loading: false,
    }
  }


  render() {
    const onSubmit = (values) => {
      console.log(values)
    }

    return (
      <div className="form-holder login">
        <div className="log-in">
          <Form
            onSubmit={onSubmit}
            // initialValues={{ userType: "Trade" }}
            render={({ handleSubmit, form, submitting, pristine, values }) => (
              <form onSubmit={handleSubmit}
              // noValidate
              >
                <div className="fieldRow">
                  <Field
                    name="name"
                    placeholder="Name"
                    component="input"
                    type="text"
                    required
                  />
                  <Field
                    name="email"
                    placeholder="Email"
                    component="input"
                    type="email"
                    required
                  />
                </div>
                <Field
                  name="subject"
                  placeholder="Subject"
                  component="input"
                  type="text"
                  required
                />
                <Field
                  name="message"
                  component="textarea"
                  placeholder="Message"
                />

                <div className={'submit-wrap ' + (this.state.loading ? 'loading' : '')}>
                  <span className="loader">
                    <ReactSVG src="/images/ui/loader-form.svg" />
                  </span>
                  <input
                    type="submit"
                    value="Send Message"
                    disabled={this.state.loading}
                  />
                </div>

              </form>
            )}
          />

          <div className="error-holder">
            {this.state.error === 'no user' &&
              // eslint-disable-next-line
              <p>错误：当前输入的邮箱尚未注册，请<a onClick={() => setLoginRoute('new-account')}>注册</a>。</p>
            }
            {this.state.error === 'password wrong' &&
              // eslint-disable-next-line
              <p>错误：当前输入的密码不正确，请重试或<a onClick={() => setLoginRoute('password-request-form')}>重设密码</a>。</p>
            }
          </div>
        </div>
      </div>
    )
  }
}

export default ContactForm









