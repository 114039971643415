import React from 'react'
import { useInView } from 'react-intersection-observer'


const Banner = (props) => {
  // const { sub, main, sub2, sub3, className } = props.params;
  // const { sub, main, sub2, sub3, className, image } = props;
  const image = '/images/banner/' + props.image + '.jpg'
  const className = ''
  const [ref, inView] = useInView()
  return (
    <div id="banner" className={className + (inView ? ' in-view' : '')} ref={ref}>
      <div className="banner-bg-wrap abs-fill fade-in">
        <div className="banner-bg abs-fill img-bg-cover" style={{ backgroundImage: "url(" + image + ")" }}></div>
      </div>
    </div>


  )
}
export default Banner
