import React from "react";
// import { pageLoad } from 'js/functions'


class NotFound extends React.Component {
  
  componentDidMount() {
    // pageLoad();
  }
  

  render() {

    return ( 
      <div>
        <h1>404: not found</h1>
      </div>
    )
  }
}

export default NotFound;
