import React from 'react'
import { pageLoad } from 'js/functions'
import { InView } from 'react-intersection-observer'
import Breadcrumb from 'components/Breadcrumb'
import Banner from 'components/Banner'
import Footer from 'components/Footer'
import TitleBlock from 'components/TitleBlock'
import ContactForm from 'forms/ContactForm'

// import { NavLink } from 'react-router-dom';



class Landing extends React.Component {

  componentDidMount() {
    pageLoad()
  }

  render() {
    // const baseSlug = this.props.match.params.slug
    // const { titleBlock, subpageHolder, textImageBlocks, textBulletsImage, nextSteps } = this.props.content
    const baseDir = window.location.pathname.split('/')[1]
    const bannerImage = {
      'join-us': 'girl-pointing',
      'contact': 'binoculars-skyline'
    }
    const { titleBlock, textImageOffset, formBlock } = this.props.content
    return (
      <div id="template-form" >
        <Banner image={bannerImage[baseDir]} />
        <div id="main-wrap">
          <div className="upper">
            <Breadcrumb />
            <TitleBlock title={titleBlock} />
          </div>

          <InView>
            {({ inView, ref }) => (
              <div className={`text-image-offset ${(inView ? 'in-view' : '')} fade-in`} ref={ref}>
                <div className="inner">
                  <div className="image fade-in-right stagger-1000">
                    <img src={textImageOffset.image} alt="" />
                  </div>
                  <div className="text">
                    <h2>{textImageOffset.title}</h2>
                    <div dangerouslySetInnerHTML={{ __html: textImageOffset.body }} />
                  </div>
                </div>
              </div>
            )}
          </InView>

          <InView>
            {({ inView, ref }) => (
              <div className={`form-block ${(formBlock.image ? 'has-image' : '')} ${(inView ? 'in-view' : '')} fade-in`} ref={ref}>
                <div className="title">
                  <div className="inner">
                    <h2>{formBlock.title}</h2>
                  </div>
                </div>
                <div className="bottom">
                  <div className="inner">
                    <div className="form">
                      <ContactForm />
                    </div>
                    <div className="side">
                      {formBlock.image &&
                        <div className="image fade-in-left stagger-1500">
                          <img src={formBlock.image} alt="" />
                        </div>
                      }
                      {formBlock.contactInfo &&
                        <div className="contactInfo">
                          <div>
                            <p><strong>Phone:</strong> +86 21 6266 1253 <br />
                              <strong>Email:</strong> <a href="mailto:admin@talentfirst.com.cn">admin@talentfirst.com.cn</a></p>
                          </div>
                          <div>
                            <p><strong>Shanghai Office</strong> <br />
                              Jingan Zi yuan<br />
                              23rd Floor, Administrative Building<br />
                              355 Wuding Road<br />
                              Jing’An District<br />
                              Shanghai PRC 200041</p>
                            <a className="image" href="https://goo.gl/maps/6AnAZhGWBzaYGHzu7" target="_blank" rel="noopener noreferrer">
                              <img src="/images/content/office-map.jpg" alt="" />
                            </a>
                          </div>
                        </div>
                      }

                    </div>
                  </div>
                </div>
              </div>
            )}
          </InView>

          <Footer />

        </div>
      </div>
    )
  }
}

export default Landing
