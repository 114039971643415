import React from 'react'
import BgLink from 'components/BgLink'
import { InView } from 'react-intersection-observer'



const NextSteps = (props) => {
  const { headline, one, two } = props.content
  return (
    <InView>
      {({ inView, ref }) => (
        <div className={`next-steps ${(inView ? 'in-view' : '')}`} ref={ref}>

          <h3 className="italic fade-in">{headline}</h3>
          <div className="steps">
            <div className="step fade-in-right stagger-500">
              <h2>{one.title}</h2>
              <BgLink className="step" to={one.linkTo} text={one.linkText} isButton={true} isWhite={true} />
            </div>
            <div className="step fade-in-left stagger-500">
              <h2>{two.title}</h2>
              <BgLink className="step" to={two.linkTo} text={two.linkText} isButton={true} isWhite={true} />
            </div>
          </div>
        </div>
      )}
    </InView>

  )
}


export default NextSteps
