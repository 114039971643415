import React from 'react'
import './styles/styles.scss';
import AppRouter from './routers/AppRouter';
import ApolloClient from "apollo-boost";
import { ApolloProvider } from "react-apollo";
import { HelmetProvider } from 'react-helmet-async';


const client = new ApolloClient({
  // Change this to the URL of your WordPress site.
  // uri: "http://wp-talentfirst:8888/graphql"
  uri: "https://wp.tf.lanternbox.com/graphql"
});


function App() {
  return (
    <HelmetProvider>
      <ApolloProvider client={client}>
        <AppRouter></AppRouter>
      </ApolloProvider>
    </HelmetProvider>
  );
}

export default App;


