import React from 'react'
import Landing from 'templates/Landing'
import { Query } from 'react-apollo'
import gql from 'graphql-tag'



const GET_ITEMS = gql`
  {
    e_nurture_items {
      edges {
        node {
          title
          slug
          featuredImage {
            sourceUrl
          }
        }
      }
    }
  }
`

const Index = (props) => {
  
  return (
    <div>
      <Query query={GET_ITEMS}>
        {({ loading, error, data }) => {
          if (loading) return <div className="loader" />
          if (error) return <p>Error :(</p>
          if (!data.e_nurture_items.edges.length) return <p>No matching posts found.</p>
          let items = data.e_nurture_items.edges
          return <Inner items={items} />
        }}
      </Query>
    </div>
  )
}


class Inner extends React.Component {
  render() {
    const { items } = this.props
    const content = {
      titleBlock: 'Let Talent First protect your Brand from the inside.',
      subpageHolder: {
        // body: `<p>Most importantly, we can connect you with companies that have demand for your skill set:</p>`,
        subpages: items
      },
      textImageBlocks: [
        {
          title: 'Issues to Expect',
          body: `<p>In today’s workplace if you truly want your business to have a strong employer brand you and your front-line managers will have the tools and access to services to help deal with important critical incidents, compliance, liability issues. You are also likely to have in place workplace programs that ensure employee wellbeing.</p>
          <p>The 20% of your workforce who will experience life problems account for most involuntary terminations. This group is more likely to file compensation claims, be absent from work fore often, incur greater costs and lower your retention rates.</p>`,
          image: '/images/content/paper-airplane-background.jpg'
        },
      ],
      textBulletsImage: {
        top: {
          title: 'NURTURE Solutions',
          body: `<p>Talent First NURTURE solutions are designed to be implemented components of, or to be your company's inclusive Employee Assistance Program (EAP). </p>`
        },
        bottom: {
          body: `<p>An EAP solution is a worksite-based program designed to help employees deal with the everyday issues involved in balancing work and life, as well as more serious problems that may impact work performance.</p>
          <p>The provision of such solutions to your employees could be the one item that sets you apart from your competitors and has YOUR COMPANY RECOGNISED WITHIN YOUR INDUSTRY AS THE BEST COMPANY TO WORK FOR!</p>
          <p>The Talent First EAP solution is confidential and customised to your organisational and employee needs. To garner a greater understanding of the Talent First EAP services please click on one of the links below.</p>`,
          image: '/images/content/girl-pointing.jpg'
        }
      },
      nextSteps: {
        headline: 'Ready to get started?',
        one: {
          title: 'Learn More.',
          linkTo: '/employers/nurture/career-support-transition',
          linkText: 'Career Support & Transition'
        },
        two: {
          title: 'Speak to a consultant.',
          linkTo: '/contact',
          linkText: 'Contact Us'
        }
      }
    }
    return (
      <Landing content={content} />
    )
  }
}

export default Index
