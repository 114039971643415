import React from 'react'
import Landing from 'templates/Landing'
import { Query } from 'react-apollo'
import gql from 'graphql-tag'



const GET_ITEMS = gql`
  {
    js_find_items {
      edges {
        node {
          title
          slug
          featuredImage {
            sourceUrl
          }
        }
      }
    }
  }
`

const Index = (props) => {
  return (
    <div>
      <Query query={GET_ITEMS}>
        {({ loading, error, data }) => {
          if (loading) return <div className="loader" />
          if (error) return <p>Error :(</p>
          if (!data.js_find_items.edges.length) return <p>No matching posts found.</p>

          let items = data.js_find_items.edges
          return <Inner items={items} />
        }}
      </Query>
    </div>
  )
}

class Inner extends React.Component {
  render() {
    const { items } = this.props
    const content = {
      titleBlock: 'At Talent First, we understand your current job role, your potential career path, and the industry in which you work.',
      subpageHolder: {
        body: `<p>Most importantly, we can connect you with companies that have demand for your skill set:</p>`,
        subpages: items
      },
      textImageBlocks: [
        {
          title: 'Our Advantage',
          body: `<p>The Talent First team have your career interests and well being at heart. The Talent First team will act as career advisors as opposed to recruiters, we are interested in placing you into a role that advances your career to the next level and provides you with greater personal reward, work life balance and a clarity in career path.</p>`,
          image: '/images/content/paper-airplane-background.jpg'
        },
        {
          title: 'Your Expert Partners in Recruitment',
          body: `<p>As a one of the few recruitment firms in China working to a partnership model, there is a good chance that the contact points within our business are shareholders in Talent First. This ensures that you are dealing with someone who has years of experience in recruitment and also understands your unique industry and the talent pool within it. That means you are dealing with experts!</p>`,
          image: '/images/content/illustrated-team.png'
        },
      ],
      textBulletsImage: {
        top: {
          title: 'Candidate & Referral Charter',
          body: `<p>We want to attract the best job seekers to work with us on a permanent, contract and interim or roles; likewise we want to help you find the most suitable opportunities. The type of opportunity that will define your career. As such:</p>`
        },
        bottom: {
          body: `<ul><li>We are proactive in finding you the most suitable roles within the organisations that are most relevant to your career aspirations.</li>
          <li>We update you as soon as such roles arise.</li>
          <li>You will be treated with integrity, professionalism and respect and our communication will be open and transparent.</li>
          <li>We will treat your personal details and situation in the utmost confidence and only submit your details to potential employers upon your confirmation to do so.</li></ul>`,
          image: '/images/content/girl-pointing.jpg'
        }
      },
      nextSteps: {
        headline: 'Ready to get started?',
        one: {
          title: 'Learn more.',
          linkTo: '/job-seekers/find/executive-search',
          linkText: 'Executive Search'
        },
        two: {
          title: 'Speak to a consultant.',
          linkTo: '/contact',
          linkText: 'Contact Us'
        }
      }
    }
    return (
      <Landing content={content} />
    )
  }
}

export default Index;
