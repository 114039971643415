
// const testing = false;

export const pageLoad = () => {
  window.scrollTo(0,0); 
  setTimeout( () => checkMenuBg(), 1)
}


const checkMenuBg = () => {
  if (window.location.pathname === '/') {  // home
    document.body.classList.add('menu-has-bg')
  } else {
    document.body.classList.remove('menu-has-bg')
  }
}