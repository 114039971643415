import React from 'react'
import { Helmet } from 'react-helmet'
import { pageLoad } from 'js/functions'
import { InView } from 'react-intersection-observer'
import Footer from 'components/Footer'
import ScrollDown from 'components/ScrollDown'
import NextSteps from 'components/NextSteps'
import ValuesBlock from 'components/ValuesBlock'
import BgLink from 'components/BgLink'
import RVideo from 'components/RVideo'



class Home extends React.Component {

  componentDidMount() {
    pageLoad()
    document.body.classList.add('menu-has-bg')
  }
  componentWillUnmount() {
    document.body.classList.remove('menu-has-bg')
  }

  render() {
    const nextSteps = {
      headline: 'Ready to get started?',
      one: {
        title: 'Find a job.',
        linkTo: '/job-seekers/find',
        linkText: 'Find'
      },
      two: {
        title: 'Find some talent.',
        linkTo: '/employers/find',
        linkText: 'Find'
      }
    }

    return (
      <div id="template-home">
        <Helmet>
          <title>Talent First | HR &amp; Recruitment Agency in Shanghai, China</title>
        </Helmet>

        <InView>
          {({ inView, ref }) => (
            <div className={`videos ${(inView ? 'in-view' : '')} fade-in`} ref={ref}>
              <div className="item">
                <RVideo filename="candidates" />
                <div className="text">
                  <h3 className="italic">for job seekers</h3>
                  <h1>Looking for <br />a better job?</h1>
                  <div className="links">
                    {/* <BgLink to="/job-seekers/job-search" text="job search" /> */}
                    {/* <BgLink to="/job-seekers/apply-directly" text="appy directory" /> */}
                    <BgLink to="/job-seekers/find" text="learn more" isLine={true} isWhite={true} />
                    <BgLink to="/contact" text="contact us" isLine={true} isWhite={true} />
                  </div>
                </div>
              </div>
              <div className="item">
                <RVideo filename="binoculars" />
                <div className="text">
                  <h3 className="italic">for employers</h3>
                  <h1>Looking for <br />talent in China?</h1>
                  <div className="links">
                    <BgLink to="/employers/find" text="learn more" isLine={true} isWhite={true} />
                    <BgLink to="/contact" text="contact us" isLine={true} isWhite={true} />
                  </div>
                </div>
              </div>
              <ScrollDown />
            </div>
          )}
        </InView>

        <InView>
          {({ inView, ref }) => (
            <div className={`text-centered ${(inView ? 'in-view' : '')} fade-in`} ref={ref}>

              <h1>About Talent First</h1>
              <p className="bigger">Talent First provides integrated people solutions in the workplace, for the employer and the employee.</p>
              <p className="bigger">That means as a people business Talent First provides solutions for your Recruitment, EAP and Organisational and Development needs.</p>
              <BgLink to="/about" text="learn more" isLine={true} />
            </div>
          )}
        </InView>
        <InView>
          {({ inView, ref }) => (
            <div className={`image-divider ${(inView ? 'in-view' : '')}`} ref={ref}>
              <div className="inner fade-in">
                <img src="/images/content/corporate-couple.jpg" alt="" />
              </div>
              <div className="bg" />
            </div>
          )}
        </InView>
        <InView>
          {({ inView, ref }) => (
            <div className={`image-text ${(inView ? 'in-view' : '')}`} ref={ref}>
              <div className="inner fade-in">
                <div className="text">
                  <p className="bigger">Our commitment is to our clients and our candidates. </p>
                  <p className="bigger">We aspire to assist each of our candidates, clients or referrals to realise their potential in business and career. </p>
                  <BgLink to="/about/our-client-commitment" text="learn more" isLine={true} />
                </div>
                <div className="image">
                  <img src="/images/content/illustrated-team.png" alt="" />
                </div>
              </div>
            </div>
          )}
        </InView>

        <ValuesBlock isHome={true} />
        <NextSteps content={nextSteps} />





        <Footer />

      </div >
    )
  }
}

export default Home;
