import React from 'react'
import { pageLoad } from 'js/functions'
import { InView } from 'react-intersection-observer'
import Breadcrumb from 'components/Breadcrumb'
import Banner from 'components/Banner'
import NextSteps from 'components/NextSteps'
import Footer from 'components/Footer'
import TitleBlock from 'components/TitleBlock'
import { NavLink } from 'react-router-dom';



class Landing extends React.Component {


  componentDidMount() {
    pageLoad()
  }

  render() {
    const baseDir = window.location.pathname.split('/')[1]
    const bannerImage = {
      'job-seekers': 'woman-binoculars',
      'employers': 'corporate-guy-binoculars',
      'about': 'shanghai-view-distant'
    }

    const pathname = window.location.pathname
    // const { headline, body, column1, column2 } = this.props.content.acf
    const { titleBlock, subpageHolder, textImageBlocks, textBulletsImage, nextSteps } = this.props.content
    return (

      <div id="template-landing" >
        <Banner image={bannerImage[baseDir]} />
        <div id="main-wrap">
          <div className="upper">
            <Breadcrumb />
            {titleBlock && <TitleBlock title={titleBlock} />}

            <InView>
              {({ inView, ref }) => (
                <div className={`subpage-grid-holder ${(inView ? 'in-view' : '')}`} ref={ref}>
                  <div className="fade-in" dangerouslySetInnerHTML={{ __html: subpageHolder.body }} />
                  <div className="subpage-grid">
                    {subpageHolder.subpages.map((subpage, index) => {
                      return (
                        <NavLink key={subpage.node.title} className={`subpage fade-in-up stagger-${(500 + (index * 100))}`} to={`${pathname}/${subpage.node.slug}`}>
                          {subpage.node.featuredImage &&
                            <img src={subpage.node.featuredImage.sourceUrl} alt="" />
                          }
                          <p dangerouslySetInnerHTML={{ __html: subpage.node.title }} />
                        </NavLink>
                      )
                    })}
                  </div>
                </div>
              )}
            </InView>
          </div>

          <div className="text-image-blocks">
            {textImageBlocks.map(block => {
              return (
                <InView key={block.title}>
                  {({ inView, ref }) => (
                    <div className={`text-image ${(inView ? 'in-view' : '')} fade-in`} ref={ref}>
                      <div className="inner">
                        <div className="text fade-in stagger-800">
                          <div className="inner">
                            <h2>{block.title}</h2>
                            <div dangerouslySetInnerHTML={{ __html: block.body }} />
                          </div>
                        </div>
                        <div className="image fade-in">
                          <img src={block.image} alt="" />
                        </div>
                      </div>
                    </div>
                  )}
                </InView>
              )
            })}
          </div>

          {textBulletsImage &&
            <InView>
              {({ inView, ref }) => (
                <div className={`text-bullets-image ${(inView ? 'in-view' : '')} fade-in`} ref={ref}>
                  <div className="top">
                    <h2>{textBulletsImage.top.title}</h2>
                    <div dangerouslySetInnerHTML={{ __html: textBulletsImage.top.body }} />
                  </div>
                  <div className="bottom">
                    <div className="text" dangerouslySetInnerHTML={{ __html: textBulletsImage.bottom.body }} />
                    <div className="image fade-in stagger-800">
                      <img src={textBulletsImage.bottom.image} alt="" />
                    </div>
                  </div>
                </div>
              )}
            </InView>
          }

          <NextSteps content={nextSteps} />
          <Footer />

        </div>
      </div>

    )
  }
}

export default Landing
