import React from 'react'
import { pageLoad } from 'js/functions'
import { InView } from 'react-intersection-observer'
import BgLink from 'components/BgLink'
import Breadcrumb from 'components/Breadcrumb'
import Banner from 'components/Banner'
import NextSteps from 'components/NextSteps'
import { Query } from 'react-apollo'
import gql from 'graphql-tag'
import NotFound from 'pages/NotFound'
import Footer from 'components/Footer'
import TitleBlock from 'components/TitleBlock'


const gqlQueries = {
  JS_FIND_ITEM: gql`
    query ($slug: String!) {
      js_find_itemBy(slug: $slug) {
        title
        date
        slug
        featuredImage {
          sourceUrl
        }
        acf {
          headline
          body
          column1 {
            text
          }
          column2 {
            text
          }
          column3 {
            text
          }
          learnMore {
            link
            text
          }
        }
      }  
    }
  `,
  E_FIND_ITEM: gql`
    query ($slug: String!) {
      e_find_itemBy(slug: $slug) {
        title
        date
        slug
        featuredImage {
          sourceUrl
        }
        acf {
          headline
          body
          column1 {
            text
          }
          column2 {
            text
          }
          column3 {
            text
          }
          learnMore {
            link
            text
          }
        }
      }  
    }
  `,
  E_NURTURE_ITEM: gql`
    query ($slug: String!) {
      e_nurture_itemBy(slug: $slug) {
        title
        date
        slug
        featuredImage {
          sourceUrl
        }
        acf {
          headline
          body
          column1 {
            text
          }
          column2 {
            text
          }
          learnMore {
            link
            text
          }
        }
      }  
    }
  `,
  E_RETAIN_ITEM: gql`
    query ($slug: String!) {
      e_retain_itemBy(slug: $slug) {
        title
        date
        slug
        featuredImage {
          sourceUrl
        }
        acf {
          headline
          body
          column1 {
            text
          }
          column2 {
            text
          }
          learnMore {
            link
            text
          }
        }
      }  
    }
  `,
  ABOUT_ITEM: gql`
    query ($slug: String!) {
      about_itemBy(slug: $slug) {
        title
        date
        slug
        featuredImage {
          sourceUrl
        }
        acf {
          headline
          body
          column1 {
            text
          }
          column2 {
            text
          }
          column3 {
            text
          }
          learnMore {
            link
            text
          }
        }
      }  
    }
  `
}



class Inner extends React.Component {

  componentDidMount() {
    pageLoad()
  }

  getSectionFromPathname() {
    // get pathArray from pathname
    const pathname = this.props.location.pathname
    let pathArray = pathname.split('/')
    pathArray.shift()

    // get thisSectionSlug from pathArray
    let thisSectionSlug = ''
    switch (pathArray[0]) {
      case ('job-seekers'): {
        thisSectionSlug = 'js_find'
        break
      }
      case ('employers'): {
        switch (pathArray[1]) {
          case ('find'): {
            thisSectionSlug = 'e_find'
            break
          }
          case ('nurture'): {
            thisSectionSlug = 'e_nurture'
            break
          }
          case ('retain'): {
            thisSectionSlug = 'e_retain'
            break
          }
          default: { }
        }
        break
      }
      case ('about'): {
        thisSectionSlug = 'about'
        break
      }
      default: { }
    }

    // get thisSection from thisSectionSlug
    const sections = {
      js_find: {
        m0: 'job-seekers',
        m1: 'find',
        gqlQuery: gqlQueries.JS_FIND_ITEM,
        gqlData: 'js_find_itemBy'
      },
      e_find: {
        m0: 'employers',
        m1: 'find',
        gqlQuery: gqlQueries.E_FIND_ITEM,
        gqlData: 'e_find_itemBy'
      },
      e_nurture: {
        m0: 'employers',
        m1: 'nurture',
        gqlQuery: gqlQueries.E_NURTURE_ITEM,
        gqlData: 'e_nurture_itemBy'
      },
      e_retain: {
        m0: 'employers',
        m1: 'retain',
        gqlQuery: gqlQueries.E_RETAIN_ITEM,
        gqlData: 'e_retain_itemBy'
      },
      about: {
        m0: 'about',
        gqlQuery: gqlQueries.ABOUT_ITEM,
        gqlData: 'about_itemBy'
      },
    }

    const thisSection = sections[thisSectionSlug]
    return thisSection
  }

  render() {
    const baseSlug = this.props.match.params.slug
    const thisSection = this.getSectionFromPathname()
    const gqlVars = { slug: baseSlug }


    // Industries Placed:
    // Positions Placed:
    // except shared services

    return (
      <Query query={thisSection.gqlQuery} variables={gqlVars} >
        {({ loading, error, data }) => {
          if (loading) return <div className="loader" />
          if (error) return <NotFound />
          const content = data[thisSection.gqlData]
          return <InnerLoaded content={content} thisSection={thisSection} />
        }}
      </Query>
    )
  }
}

class InnerLoaded extends React.Component {
  render() {
    const baseDir = window.location.pathname.split('/')[1]
    const bannerImage = {
      'job-seekers': 'woman-binoculars',
      'employers': 'corporate-guy-binoculars',
      'about': 'shanghai-view-distant'
    }
    const { headline, body, column1, column2, column3, learnMore } = this.props.content.acf
    const { featuredImage, slug } = this.props.content
    const { thisSection } = this.props
    // const featuredImage = "/images/content/placeholder.jpg"
    const slides = [1, 2, 3, 4]
    const nextSteps = {
      headline: 'Ready to get started?',
      one: {
        title: 'Learn more.',
        linkTo: learnMore.link,
        linkText: learnMore.text
      },
      two: {
        title: 'Speak to a consultant.',
        linkTo: '/contact',
        linkText: 'Contact Us'
      }
    }

    let columnTitle1 = 'Industries Placed'
    let columnTitle2 = 'Positions Placed'
    let columnTitle3 = 'Legal'

    if (slug === 'shared-services') {
      columnTitle1 = 'Accounting and Finance'
      columnTitle2 = 'Human Resources'
      columnTitle3 = 'Legal'
    }
    if (slug === 'critical-incident-response') {
      columnTitle1 = 'CISM Services'
    }
    if (slug === 'manager-support') {
      columnTitle1 = 'Manager Support Services'
    }
    if (slug === 'competency-profiling') {
      columnTitle1 = 'Competency Based Activities'
    }
    if (slug === 'employee-handbook') {
      columnTitle1 = 'Branding Services'
    }

    return (
      <div id="template-inner">
        <Banner image={bannerImage[baseDir]} />
        <div id="main-wrap">
          <div className="upper">
            <Breadcrumb />
            {headline && <TitleBlock title={headline} />}
            <InView>
              {({ inView, ref }) => (
                <div className={`text-image ${(inView ? 'in-view' : '')} fade-in`} ref={ref}>
                  <div className="text" dangerouslySetInnerHTML={{ __html: body }} />
                  {featuredImage &&
                    <div className="image">
                      <img src={featuredImage.sourceUrl} alt="" />
                    </div>
                  }
                </div>
              )}
            </InView>
          </div>
          {(column1 || column2 || column3) &&
            <InView>
              {({ inView, ref }) => (
                <div className={`columns-holder ${(inView ? 'in-view' : '')} fade-in`} ref={ref}>
                  <div className="inner">
                    <div className="columns-2">
                      {column1 &&
                        <div className="column fade-in stagger-500">
                          <h2>{columnTitle1}:</h2>
                          <ul>
                            {column1.map(item => <li key={item.text} dangerouslySetInnerHTML={{ __html: item.text }} />)}
                          </ul>
                        </div>
                      }
                      {column2 &&
                        <div className="column fade-in stagger-700">
                          <h2>{columnTitle2}:</h2>
                          {/* <h2>Positions Placed:</h2> */}
                          <ul>
                            {column2.map(item => <li key={item.text}>{item.text}</li>)}
                          </ul>
                        </div>
                      }
                      {column3 &&
                        <div className="column fade-in stagger-900">
                          <h2>{columnTitle3}:</h2>
                          <ul>
                            {column3.map(item => <li key={item.text}>{item.text}</li>)}
                          </ul>
                        </div>
                      }
                    </div>
                    <div className="image fade-in-left stagger-1500">
                      <img src="/images/content/illustrated-interview.png" alt="" />
                    </div>
                  </div>
                </div>
              )}
            </InView>

          }
          {thisSection.m1 === 'find' &&
            <InView>
              {({ inView, ref }) => (
                <div className={`slideshow-holder ${(inView ? 'in-view' : '')} fade-in`} ref={ref}>
                  <h1>Related Jobs</h1>
                  <div className="slideshow-wrap">
                    <div className="slideshow-arrow right"><button /></div>
                    <div className="slideshow-arrow left"><button /></div>
                    <div className="inner">
                      <div className="slides">
                        {slides.map((slide, index) => {
                          return (
                            <div key={slide} className={`slide fade-in-up stagger-${(500 + (index * 100))}`}>
                              <h4>2019-05-21</h4>
                              <h3>Asia Pacific Sales Director</h3>
                              <h5>Shanghai</h5>
                              <p>Our Client is a world leader with more than 150,000 professionals in 70 countries and draws together the worlds of food, agriculture, and…</p>
                              <BgLink to="/" text="More »" />
                            </div>
                          )
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </InView>

          }

          <NextSteps content={nextSteps} />
          <Footer />
        </div>
      </div >
    )
  }
}

export default Inner
