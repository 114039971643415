import React from 'react'
import { Query } from 'react-apollo'
import gql from 'graphql-tag'
import { Helmet } from 'react-helmet'
import { pageLoad } from 'js/functions'
import Breadcrumb from 'components/Breadcrumb'
import Banner from 'components/Banner'
import NextSteps from 'components/NextSteps'
import Footer from 'components/Footer'
import TitleBlock from 'components/TitleBlock'
import { NavLink } from 'react-router-dom';
import { InView } from 'react-intersection-observer'




const GET_ITEMS = gql`
  {
    about_items {
      edges {
        node {
          title
          slug
          featuredImage {
            sourceUrl
          }
        }
      }
    }
  }
`




const Index = (props) => {
  return (
    <div>
      <Query query={GET_ITEMS}>
        {({ loading, error, data }) => {
          if (loading) return <div className="loader" />
          if (error) return <p>Error :(</p>
          if (!data.about_items.edges.length) return <p>No matching posts found.</p>

          let items = data.about_items.edges
          return <Inner items={items} />
        }}
      </Query>
    </div>
  )
}


class Inner extends React.Component {

  componentDidMount() {
    pageLoad()
  }


  render() {
    const { items } = this.props

    const content = {
      titleBlock: 'Talent First provides integrated people solutions in the workplace, for the employer and the employee.',
      subpageHolder: {
        title: `<h2>More About Us</h2>`,
        subpages: items
      },
      textColumns: {
        one: `<p>That means as a people business Talent First provides solutions for your Recruitment, EAP and Organisational and Development needs. Our commitment is to our clients and our candidates. We aspire to assist each of our candidates, clients or referrals to realise their potential in business and career.</p>`,
        two: `<p>Through years of experience, we have recognised the value gained by each party through having content employees in the right job. Getting this choice right can transform business, career and life. We are passionate about this and it shows in every placement and project that we take on board.</p>`
      },
      textImageBlock: {
        title: 'Our Workplace',
        body: `<p>We strive to be recognised as one of the best employers in the HR industry in China. At Talent First, we deploy a partnership model that offers an attractive workplace in every manner, including flexible work hours, annual leave above government standard, remuneration above the industry norm and a fun workplace to boot.</p>`,
        image: '/images/content/office-working-together.jpg'
      },
      nextSteps: {
        headline: 'Ready to get started?',
        one: {
          title: 'Learn More.',
          linkTo: '/about/brand-promise',
          linkText: 'Brand Promise'
        },
        two: {
          title: 'Speak to a consultant.',
          linkTo: '/contact',
          linkText: 'Contact Us'
        }
      }
    }
    const { titleBlock, subpageHolder, textColumns, textImageBlock, nextSteps } = content

    return (
      <div id="template-about" className="landing">
        <Helmet>
          <title>Talent First | About</title>
        </Helmet>
        <Banner image="shanghai-view-distant" />
        <div id="main-wrap">
          <div className="upper">
            <Breadcrumb />
            <TitleBlock title={titleBlock} />
            <InView>
              {({ inView, ref }) => (
                <div className={`text-2-columns ${(inView ? 'in-view' : '')} fade-in`} ref={ref}>
                  <div className="column" dangerouslySetInnerHTML={{ __html: textColumns.one }} />
                  <div className="column" dangerouslySetInnerHTML={{ __html: textColumns.two }} />
                </div>
              )}
            </InView>

          </div>
          <InView>
            {({ inView, ref }) => (
              <div className={`text-image-shaded ${(inView ? 'in-view' : '')} fade-in`} ref={ref}>
                <div className="text fade-in stagger-300">
                  <div className="inner">
                    <h2>{textImageBlock.title}</h2>
                    <div dangerouslySetInnerHTML={{ __html: textImageBlock.body }} />
                  </div>
                </div>
                <div className="image">
                  <img src={textImageBlock.image} alt="" />
                </div>
              </div>
            )}
          </InView>

          <InView>
            {({ inView, ref }) => (
              <div className={`subpage-grid-holder ${(inView ? 'in-view' : '')} fade-in`} ref={ref}>
                <div dangerouslySetInnerHTML={{ __html: subpageHolder.title }} />
                <div className="subpage-grid">
                  {subpageHolder.subpages.map((subpage, index) => (
                    <NavLink key={index} className={`subpage fade-in-up stagger-${(500 + (index * 100))}`} to={`about/${subpage.node.slug}`}>
                      {subpage.node.featuredImage &&
                        <img src={subpage.node.featuredImage.sourceUrl} alt="" />
                      }
                      <p dangerouslySetInnerHTML={{ __html: subpage.node.title }} />
                    </NavLink>
                  ))}
                </div>
              </div>
            )}
          </InView>

          <NextSteps content={nextSteps} />
          <Footer />

        </div>
      </div>

    )
  }
}

export default Index
