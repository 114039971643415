import React from 'react'
import { InView } from 'react-intersection-observer'
import { NavLink } from 'react-router-dom';
// import BgLink from '../components/BgLink';


const Footer = (props) => {

  return (
    <div id="footer">



      <InView>
        {({ inView, ref }) => (
          <div className={(inView ? 'in-view' : '')} ref={ref}>
            <div className="main">
              <div className="inner grid-10-2 fade-in">
                <div className="left">
                  <div className="logo">
                    <NavLink to="/">
                      <img src="/images/vi/talentfirst_logo_white.png" alt="logo" />
                    </NavLink>
                  </div>
                  <h2>Find. Nurture. Retain.</h2>
                </div>
                <div className="mid">
                  <div className="column">
                    <h3><NavLink to="/job-seekers">For Job Seekers</NavLink></h3>
                    <ul className="text-only">
                      <li><NavLink to="/job-seekers/job-search">Job Search</NavLink></li>
                      <li><NavLink to="/job-seekers/apply-directly">Apply Directly</NavLink></li>
                      <li><NavLink to="/job-seekers/find">Find</NavLink></li>
                      <li><NavLink to="/job-seekers/join-talent-first">Join Talent First</NavLink></li>
                    </ul>
                  </div>
                  <div className="column">
                    <h3><NavLink to="/employers">For Employers</NavLink></h3>
                    <ul className="text-only">
                      <li><NavLink to="/employers/find">Find</NavLink></li>
                      <li><NavLink to="/employers/nurture">Nurture</NavLink></li>
                      <li><NavLink to="/employers/retain">Retain</NavLink></li>
                      <li><NavLink to="/employers/contact">Contact Us</NavLink></li>
                    </ul>
                  </div>
                </div>
                <div className="right">
                  <div className="column">
                    <h3><NavLink to="/contact">Contact Us</NavLink></h3>
                    <ul className="text-only">
                      <li>+86 21 6266 1253</li>
                      <li><a href="mailto:admin@talentfirst.com.cn">admin@talentfirst.com.cn</a></li>
                    </ul>
                    <h3>Follow Us</h3>
                    <ul className="text-only">
                      <li><a href="https://www.linkedin.com/company/talentfirst" target="_blank" rel="noopener noreferrer">LinkedIn</a></li>
                      <li>WeChat</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="credit">
              <div className="inner fade-in">
                <p>© Copyright Talent First. All rights reserved. Credit: <a href="https://www.lanterndesign.com" target="_blank" rel="noopener noreferrer">web design</a></p>
              </div>
            </div>
          </div>
        )}
      </InView>

    </div>
  );
}
export default Footer;
