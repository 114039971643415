import React from 'react'
import { NavLink, withRouter } from 'react-router-dom';




class Menu extends React.Component {


  constructor(props) {
    super(props);
    this.state = {
      menuOpen: false,
    };
    this.menuToggle = this.menuToggle.bind(this);
    this.menuClose = this.menuClose.bind(this);
  }

  menuToggle() {
    this.setState((prevState) => ({
      menuOpen: !prevState.menuOpen
    }));
  }
  menuClose() {
    this.setState({
      menuOpen: false
    })
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.menuClose();
    }
  }

  render() {

    const menu = [
      {
        slug: '',
        text: 'Home'
      },
      {
        slug: 'job-seekers',
        text: 'Job Seekers',
        sub: [
          {
            slug: 'find',
            text: 'Find',
            sub: [
              {
                slug: 'executive-search',
                text: 'Executive Search'
              },
              {
                slug: 'flight-deck',
                text: 'Flight Deck'
              },
              {
                slug: 'health-care-life-science',
                text: 'Health Care & Life Science'
              },
              {
                slug: 'private-equity-venture-capital',
                text: 'Private Equity & Venture Capital'
              },
              {
                slug: 'sales-marketing',
                text: 'Sales & Marketing'
              },
              {
                slug: 'shared-services',
                text: 'Shared Services'
              },
              {
                slug: 'supply-chain-operations',
                text: 'Supply Chain & Operations'
              }
            ]
          }
        ]
      },
      {
        slug: 'employers',
        text: 'Employers',
        sub: [
          {
            slug: 'find',
            text: 'Find',
            sub: [
              {
                slug: 'executive-search',
                text: 'Executive Search'
              },
              {
                slug: 'flight-deck',
                text: 'Flight Deck'
              },
              {
                slug: 'health-care-life-science',
                text: 'Health Care & Life Science'
              },
              {
                slug: 'private-equity-venture-capital',
                text: 'Private Equity & Venture Capital'
              },
              {
                slug: 'sales-marketing',
                text: 'Sales & Marketing'
              },
              {
                slug: 'shared-services',
                text: 'Shared Services'
              },
              {
                slug: 'supply-chain-operations',
                text: 'Supply Chain & Operations'
              }
            ]
          },
          {
            slug: 'nurture',
            text: 'Nurture',
            sub: [
              {
                slug: 'career-support-transition',
                text: 'Career Support & Transition'
              },
              {
                slug: 'critical-incident-response',
                text: 'Critical Incident Response'
              },
              {
                slug: 'employee-well-being',
                text: 'Employee Well Being'
              },
              {
                slug: 'manager-support',
                text: 'Manager Support'
              },
              {
                slug: 'mediation',
                text: 'Mediation'
              }
            ]
          },
          {
            slug: 'retain',
            text: 'Retain',
            sub: [
              {
                slug: 'coaching-mentoring',
                text: 'Coaching & Mentoring'
              },
              {
                slug: 'competency-profiling',
                text: 'Competency Profiling'
              },
              {
                slug: 'employee-handbook',
                text: 'Employee Handbook'
              },
              {
                slug: 'job-analysis',
                text: 'Job Analysis'
              },
              {
                slug: 'performance-management-systems',
                text: 'Performance Management Systems'
              }
            ]
          }
        ]
      },
      {
        slug: 'about',
        text: 'About',
        sub: [
          {
            slug: 'brand-promise',
            text: 'Brand Promise'
          },
          {
            slug: 'our-client-commitment',
            text: 'Our Client Commitment'
          },
          {
            slug: 'candidate-referral-commitment',
            text: 'Candidate & Referral Commitment'
          },
          {
            slug: 'brand-values',
            text: 'Brand Values'
          }
        ]
      },
      {
        slug: 'join-us',
        text: 'Join Us'
      },
      {
        slug: 'contact',
        text: 'Contact'
      }
    ]


    const { firstLoadLoaded } = this.props;
    return (
      <div id="menu" className={(this.state.menuOpen) ? 'open' : ''}>


        <div className={'loaded-wrap ' + (firstLoadLoaded ? '' : 'up')}>
          <div className="thumb" onClick={this.menuToggle}>
            <div className="one"></div>
            <div className="two"></div>
            <div className="three"></div>
          </div>
          <div className="inner">
            <NavLink className="logo" to="/">
              <img src="/images/vi/talentfirst_logo.png" alt="logo" />
            </NavLink>
            {/* <div className="thumb" onClick={this.props.headerToggle}> */}



            <ul className="main">
              {menu.map(m0 => {
                return (
                  <li key={m0.slug}>
                    <NavLink to={`/${m0.slug}`} exact={(m0.slug === '')}><div className="inner">{m0.text}</div></NavLink>
                    {m0.sub &&
                      <ul className="sub">
                        {m0.sub.map((m1) => {
                          return (
                            <li key={m1.slug}>
                              <NavLink to={`/${m0.slug}/${m1.slug}`}>{m1.text}</NavLink>
                              {m1.sub &&
                                <ul className="sub-2">
                                  {m1.sub.map((m2) => {
                                    return (
                                      <li key={m2.slug}><NavLink to={`/${m0.slug}/${m1.slug}/${m2.slug}`}>{m2.text}</NavLink></li>
                                    )
                                  })}
                                </ul>
                              }
                            </li>
                          )
                        })}
                      </ul>
                    }
                  </li>
                )
              })}
            </ul>


          </div>
        </div>
      </div>
    )
  }

}


export default withRouter(Menu);
