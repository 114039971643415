import React from 'react'
import { BrowserRouter } from 'react-router-dom';

import Menu from '../components/Menu';
import Container from '../components/Container';

import $ from "jquery";

class AppRouter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isTop: true,
      lastScrollY: 0
    };
    this.topTrue = this.topTrue.bind(this);
    this.topFalse = this.topFalse.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this.smartMenu = this.smartMenu.bind(this);
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
    setTimeout(() => {
      this.setState({ firstLoadLoaded: true });
    }, 1);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll() {
    if (window.scrollY <= 100) {
      this.topTrue();
    } else {
      this.topFalse();
    }
    const bannerBg = $('.banner-bg');
    const bannerHeight = bannerBg.height();

    window.requestAnimationFrame(this.smartMenu);
    if (window.scrollY < bannerHeight) {
      window.requestAnimationFrame(this.animateBg);
      $('body').removeClass('menu-has-bg')
    } else {
      $('body').addClass('menu-has-bg')
    }
    // this.animateBg();
  }





  smartMenu() {
    const menu = $('#menu');
    const delta = 5;
    const thisScrollY = window.scrollY; // new variable = distance from top of screen
    const scrollDistance = thisScrollY - this.state.lastScrollY;
    if (Math.abs(scrollDistance) <= delta) // if the difference since last scrolling is less than 5,
      return; // do nothing
    if (menu.hasClass('stick')) return;
    if (thisScrollY < 200) {
      menu.removeClass('up');
      return;
    }
    if (scrollDistance > 0) { // if scrolling down,
      menu.addClass('up');
    } else { // if scrolling up,
      menu.removeClass('up');
    }
    if (window.innerHeight + thisScrollY >= document.body.offsetHeight) { // hit bottom of page
      menu.removeClass('up');
    }
    this.setState({ lastScrollY: thisScrollY });
  }



  animateBg() {
    // const bannerBg = document.querySelector('.banner-bg');
    // bannerBg.style.transform = `translate3d(0,${setScroll}px,0)`;

    const bannerBg = $('.banner-bg');
    const bannerHeight = bannerBg.height();

    const setScroll = window.scrollY * -.1;
    const setOpacity = (bannerHeight - window.scrollY) / bannerHeight;
    // const setOpacity = (((bannerHeight - window.scrollY) / bannerHeight) / 2) + .5;
    bannerBg.css({ 'transform': 'translate3d(0, ' + setScroll + 'px, 0)' });
    bannerBg.css({ 'opacity': setOpacity });

  }

  topTrue() {
    this.setState({ isTop: true });
  }
  topFalse() {
    this.setState({ isTop: false });
  }





  render() {
    
    return (
      <BrowserRouter>
        <div className={'loader first-load ' + (this.state.firstLoadLoaded ? 'loaded' : '')}></div>
        <Menu firstLoadLoaded={(this.state.firstLoadLoaded ? 'loaded' : '')} />
        <Container />
      </BrowserRouter>
    )
  }
}


export default AppRouter;
