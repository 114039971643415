import React from 'react'
import { pageLoad } from 'js/functions'
import { InView } from 'react-intersection-observer'


import Footer from 'components/Footer'
import NextSteps from 'components/NextSteps'
import BgLink from 'components/BgLink'
import Banner from 'components/Banner'
import Breadcrumb from 'components/Breadcrumb'
import TitleBlock from 'components/TitleBlock'


class Index extends React.Component {

  componentDidMount() {
    pageLoad()
  }

  render() {
    const content = {
      titleBlock: 'The organisational and employment landscape is changing rapidly in China.',
      textImageMain: {
        image: '/images/content/emp.jpg',
        body: `<p>The organisational and employment landscape is changing rapidly in China. As China cements itself as a global economic powerhouse and the government advances employment policies, the organisational needs and demands are becoming more complex and in some instance[s] more aligned with developed economies. [The] companies that take a first mover advantage in areas such as organisational design, employee support and development will be those that are hiring, nurturing and retaining the best talent and will be recognised as the “choice employers” in China.</p>
        <p>To assist you Talent First provides our clients with a range of solutions including recruitment, employee assistance programs for your staff teams and talent management and development programs and solutions for deployment within your organisations.</p>`,
      },
      textImageBlocks: [
        {
          image: '/images/content/find.jpg',
          title: 'Find',
          body: 'Talent First’s Partnership Model ensures your main point of contact has years of experience in their field and understands the needs of your unique industry and the talent pool within it.',
          link: '/employers/find'
        },
        {
          image: '/images/content/nurture.jpg',
          title: 'Nurture',
          body: 'Let Talent First help your business to be recognised within your industry as the best company to work for.',
          link: '/employers/nurture',
          reverse: true
        },
        {
          image: '/images/content/retain.jpg',
          title: 'Retain',
          body: 'We will help you attract and retain the best talent in the market. We will help you attract and retain the best talent.',
          link: '/employers/retain'
        }
      ],
      nextSteps: {
        headline: 'Ready to get started?',
        one: {
          title: 'Learn More.',
          linkTo: '/employers/find',
          linkText: 'Find'
        },
        two: {
          title: 'Find some talent.',
          linkTo: '/employers/find',
          linkText: 'Contact Us'
        }
      }
    }
    const { titleBlock, textImageMain, textImageBlocks, nextSteps } = content

    return (
      <div id="template-employers">
        <Banner image='corporate-guy-binoculars' />
        <div id="main-wrap">
          <div className="upper">
            <Breadcrumb />
            <TitleBlock title={titleBlock} />

            <InView>
              {({ inView, ref }) => (
                <div className={`text-image ${(inView ? 'in-view' : '')} fade-in`} ref={ref}>
                  <div className="text">
                    <div dangerouslySetInnerHTML={{ __html: textImageMain.body }} />
                  </div>
                  <div className="image">
                    <img src={textImageMain.image} alt="" />
                  </div>
                </div>
              )}
            </InView>
          </div>

          {textImageBlocks.map(block => {
            return (
              <InView key={block.title}>
                {({ inView, ref }) => (
                  <div className={`text-image-full ${(block.reverse ? 'reverse' : '')} ${(inView ? 'in-view' : '')} ${(block.reverse ? 'fade-in-left' : 'fade-in-right')}`} ref={ref}>
                    <div className="inner">
                      <div className="image">
                        <img src={block.image} alt="" />
                      </div>
                      <div className="text">
                        <h2>{block.title}</h2>
                        <p>{block.body}</p>
                        <BgLink to={block.link} text="learn more" isLine={true} />
                      </div>
                    </div>
                  </div>
                )}
              </InView>
            )
          })}

          <NextSteps content={nextSteps} />
          <Footer />

        </div>
      </div>
    )
  }
}

export default Index;
