import React from 'react'
import Landing from 'templates/Landing'
import { Query } from 'react-apollo'
import gql from 'graphql-tag'



const GET_ITEMS = gql`
  {
    e_find_items {
      edges {
        node {
          title
          slug
          featuredImage {
            sourceUrl
          }
        }
      }
    }
  }
`

const Index = (props) => {
  return (
    <div>
      <Query query={GET_ITEMS}>
        {({ loading, error, data }) => {
          if (loading) return <div className="loader" />
          if (error) return <p>Error :(</p>
          if (!data.e_find_items.edges.length) return <p>No matching posts found.</p>

          let items = data.e_find_items.edges
          return <Inner items={items} />
        }}
      </Query>
    </div>
  )
}


class Inner extends React.Component {
  render() {
    const { items } = this.props
    const content = {
      titleBlock: 'One of the greatest challenges when recruiting in China is finding a partner who listens to you, who understands your business, your industry and most importantly has an in depth understanding of the talent pool within your niche.',
      subpageHolder: {
        // body: `<p>Most importantly, we can connect you with companies that have demand for your skill set:</p>`,
        subpages: items
      },
      textImageBlocks: [
        {
          title: 'Our Advantage',
          body: `<p>At Talent First we pride ourselves on open communication with our clients to ensure that whether you are the HR professional in charge of recruitment or the hiring manager we are meeting the needs of yours and your business needs.</p>`,
          image: '/images/content/paper-airplane-background.jpg'
        },
        {
          title: 'Your Expert Partners in Recruitment',
          body: `<p>As a one of the few recruitment firms in China working to a partnership model, there is a good chance that the contact points within our business are shareholders in Talent First. This ensures that you are dealing with someone who has years of experience in recruitment and also understands your unique industry and the talent pool within it. That means you are dealing with experts!</p>`,
          image: '/images/content/illustrated-team.png'
        },
      ],
      textBulletsImage: {
        top: {
          title: 'Our Client Commitment',
          body: `<p>At Talent First we take our brand values seriously and incorporate them into all our interactions. As one of our values clients, our team will:</p>`
        },
        bottom: {
          body: `<ul><li>Be available to visit you, at your convenience.</li>
            <li>Work hard and use a multi-faceted sourcing strategy to find the most appropriate talent to successfully fill your positions.</li>
            <li>Present your brand in the manner which you desire and always seek to raise your brand value and awareness within the talent pool.</li>
            <li>Designate an experienced account manager to look after you and your business needs. This person will be directly involved in the solution that Talent First is providing to your business.</li>
            <li>Ensure all candidates will be fully referenced and interviewed prior to submission.</li>
            <li>Be direct and open in our dealings with all parties. Transparency is one of our core brand values. Your communication with us will be held in the utmost confidence.</li>
            <li>Assist you on board potential new hires.</li></ul>`,
            image: '/images/content/girl-pointing.jpg'
        }
      },
      nextSteps: {
        headline: 'Ready to get started?',
        one: {
          title: 'Learn More.',
          linkTo: '/employers/find/executive-search',
          linkText: 'Executive Search'
        },
        two: {
          title: 'Speak to a consultant.',
          linkTo: '/contact',
          linkText: 'Contact Us'
        }
      }
    }
    return (
      <Landing content={content} />
    )
  }
}

export default Index
