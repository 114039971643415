import React from 'react'
import Form from 'templates/Form'


const content = {
  titleBlock: `If you are sick of being just another number and prefer a workplace that is fun, yet personally rewarding, then Talent First is for <strong>YOU</strong>.`,
  textImageOffset: {
    image: '/images/content/illustrated-team.png',
    title: 'Come Join in the Fun',
    body: `<p>Like our clients, we want to be recognised as one of the best employers in the Human Resource industry in China. We deploy a partnership model for senior team members and managed career paths for those starting their careers. Talent First aims to offer an attractive workplace in every manner. We include partnership opportunities, flexible work hours, annual leave above government requirement (and most our competition), outstanding personal earning opportunities and a fun workplace to boot.</p>
    <p>Stop being treated like a commodity in your current workplace. Come out and meet our team and join in the fun.  We would love to meet you!</p>`
  },
  formBlock: {
    title: 'Apply Now',
    image: '/images/content/illustrated-interview-2.png',
    form: 'join'
  }
}

const JoinUs = () => {
  return (
    <Form content={content} />
  )
}

export default JoinUs