import React from 'react'
import BgLink from 'components/BgLink'
import TitleBlock from 'components/TitleBlock'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUsers, faRetweet, faCheck, faHeart, faFolder, faThumbsUp } from '@fortawesome/free-solid-svg-icons'
import { InView } from 'react-intersection-observer'


const values = [
  {
    icon: faUsers,
    title: 'Experts',
    body: 'We fully are knowledgeable about the sectors that we support and we invest in people who can operate at the highest level.'
  },
  {
    icon: faRetweet,
    title: 'Trust',
    body: 'We seek transparency in every action that we take and work on instilling this as part of our organisational culture.'
  },
  {
    icon: faCheck,
    title: 'Consistent',
    body: 'Consistency determines how we interact with clients, candidates and colleagues – and how we operate both locally and internationally.'
  },
  {
    icon: faHeart,
    title: 'Care',
    body: 'We care about the relationships that we develop and the importance of the recruitment services that we provide.'
  },
  {
    icon: faFolder,
    title: 'Openness',
    body: 'We are open in how we approach our clients and candidates and in how we challenge ourselves.'
  },
  {
    icon: faThumbsUp,
    title: 'Fun',
    body: 'Whilst we take your business needs seriously we like to make sure that we enjoy our work.'
  }
]



const ValuesBlock = (props) => {
  const { isHome } = props
  const headline = 'With a team with over twenty years of combined experience in the recruitment, Talent management and development fields Talent First understands your business needs.'
  return (
    <div className={'values-block ' + (isHome ? 'is-home' : '')}>
      {!isHome &&
        <TitleBlock title={headline} />
      }
      <InView>
        {({ inView, ref }) => (
          <div className={`inner ${(inView ? 'in-view' : '')}`} ref={ref}>
            <div className="side">
              {isHome &&
                <div className="text fade-in">
                  <h2>Our Values</h2>
                  <p>{headline}</p>
                  <BgLink to="/about" text="learn more" isLine={true} />
                </div>
              }
              <div className="image">
                <img className="fade-in-right stagger-500" src="/images/content/illustrated-interview.png" alt="" />
              </div>
            </div>
            <div className="main">
              {values.map((item, index) => {
                return (
                  <div key={item.title} className={`item fade-in-up stagger-${(500 + (index * 100))}`}>
                    <div className="icon">
                      <FontAwesomeIcon icon={item.icon} color="white" />
                    </div>
                    <div className="text">
                      <h3>{item.title}</h3>
                      <p>{item.body}</p>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        )}
      </InView>

    </div>

  )
}

export default ValuesBlock;

